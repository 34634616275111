<template lang="pug">
  div.row
    div.col-12
      h1 Static
    div.col-12.mt-2
      table.table.summary-table
        thead
          tr
            th NotStarted
            th Skipped
            th NotApplicable
            th Applicable
            th NeedCheck
            th NoElement
            th Error
            th Total
        tbody
          tr
            td {{ summary.NotStarted }}
            td {{ summary.Skipped }}
            td {{ summary.NotApplicable }}
            td {{ summary.Applicable }}
            td {{ summary.NeedCheck }}
            td {{ summary.NoElement }}
            td {{ summary.Error }}
            td {{ summary.Total }}
    div.col-12.mt-2
      VueGoodTable.sc-list(:columns="table.columns",
                            :rows="targets",
                            :pagination-options="table.pagination_options",
                            styleClass="vgt-table striped condensed",
                            :sort-options="table.sort_options",
                            :fixed-header="true",
                            max-height="80vh",
                            @on-row-click="show_diff",
                            :row-style-class="rowStyleClassFn")
        //- Header
        template(slot="table-column", slot-scope="props")
          span(v-if="props.column.field == 'url'")
            <svg data-v-080e764a="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-external-link"><path data-v-080e764a="" d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline data-v-080e764a="" points="15 3 21 3 21 9"></polyline><line data-v-080e764a="" x1="10" y1="14" x2="21" y2="3"></line></svg>
          span(v-else-if="props.column.field == 'ss'")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image" data-v-080e764a=""><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
          span(v-else-if="props.column.field == 'interval'")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock" data-v-080e764a=""><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
          span(v-else)
            | {{ props.column.label }}
        //- Row
        template(slot="table-row", slot-scope="props")
          span(v-if="props.column.field == 'status'")
            | {{ ["NotStarted", "Skipped","NotApplicable","Applicable","NeedCheck","NoElement","Error"][props.row.status-1] }}
          span(v-else-if="props.column.field == 'url'")
            a(:href="props.row.url", target="_blank", rel="noopener")
              <svg data-v-080e764a="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-external-link"><path data-v-080e764a="" d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline data-v-080e764a="" points="15 3 21 3 21 9"></polyline><line data-v-080e764a="" x1="10" y1="14" x2="21" y2="3"></line></svg>
          span(v-else-if="props.column.field == 'ss'")
            <svg v-if="props.row.ss" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image" data-v-080e764a=""><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
          span(v-else-if="props.column.field == 'remain_tag'")
            <svg v-if="props.row.remain_tag" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else)
            | {{ props.formattedRow[props.column.field] }}
    div.col-12.mt-3
      div
        label ID
        input.form-control(type="number", v-model.number="target_id")
        button.btn.btn-outline-primary(type="button", @click="show_diff_id") Show
    div.col-12.mt-3(v-if="showing.id", :key="reset_counter")
      h4 {{ showing.id }}:{{ showing.label }}
      div(v-for="(source,area_id) in diffHTMLs")
        p {{ area_id }}
        div(v-html="source")
    div.col-12.text-center.my-3(v-if="showing.id")
      button.btn.btn-lg.btn-outline-success.mx-2(type="button", @click="result(true)") Applicable
      button.btn.btn-lg.btn-outline-danger.mx-2(type="button", @click="result(false)") Not

</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
import * as Diff from 'diff';

export default {
  name:"StaticChallengeList",
  components:{
    VueGoodTable
  },
  head:{
    title:{ inner:"V2 Static" }
  },
  data (){
    return {
      reset_counter:0,
      targets:[],
      summary:{
        NotStarted:null,
        Skipped:null,
        NotApplicable:null,
        Applicable:null,
        NeedCheck:null,
        NoElement:null,
        Error:null,
        Total:null
      },
      table:{
        pagination_options: {
          enabled: true,
          perPage: 10,
          perPageDropdown: [10, 20, 50]
        },
        sort_options:{
          enabled:true,
          initialSortBy:{field:'id', type:'asc'}
        },
        columns: [
          {
            label: 'Status',
            field: 'status',
            width: '120px',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: { enabled: true, filterDropdownItems:[{ text:"NotStarted", value:1 },{ text:"Skipped", value:2 },{ text:"NotApplicable", value:3 },{ text:"Applicable", value:4 },{ text:"NeedCheck", value:5 },{ text:"NoElement", value:6 },{ text:"Error", value:7 } ] },
          },
          {
            label: 'ID',
            field: 'id',
            width: '65px',
            type: 'number',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: { enabled: true },
          },
          {
            label: 'Label',
            field: 'label',
            sortable: false,
            filterOptions: { enabled: true, trigger: 'enter' },
          },
          {
            label: 'URL',
            field: 'url',
            width: '60px',
            thClass: 'text-center',
            tdClass: 'text-center',
            sortable: false,
            filterOptions: { enabled: true, trigger: 'enter' },
          },
          {
            label: 'SS',
            field: 'ss',
            width: '60px',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: { enabled: true, filterDropdownItems: [ { value: true, text: '利用中' }, { value: false, text: 'なし' } ] },
          },
          {
            label: 'Interval',
            field: 'interval',
            width: '65px',
            type: 'number',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: { enabled: true, filterFn: this.columnFilterFn },
          },
          {
            label: 'Login',
            field: 'login',
            width: '70px',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          {
            label: 'HTML',
            field: 'remain_tag',
            width: '70px',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ]
      },
      showing:{
        id:0,
        label:""
      },
      diffHTMLs:{},
      target_id:null
    }
  },
  async mounted (){
    await this.get();
  },
  methods:{
    loading (mes=""){
      let spinner = document.createElement("div");
      spinner.classList.add("spinner-border", "text-secondary");
      let p = document.createElement("p");
      p.innerText = mes;
      let div = document.createElement("div");
      div.appendChild(spinner);
      div.appendChild(p);
      this.$swal({ content:div, button:false });
    },
    async get (){
      try{
        this.loading();
        let res = await this.axios.get("/api/sc/list");
        this.targets = res.data.list;
        this.summary = res.data.summary;
        this.$swal.close();
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    async show_diff (params){
      if (params.row.status!=5)
        return;
      try{
        this.loading();
        let res = await this.axios.get("/api/sc/challenge?id="+params.row.id);
        let _static = res.data.static;
        let browser = res.data.browser;
        let areas = Object.keys(_static);
        this.diffHTMLs = {};
        for(let i=0;i<areas.length;i++){
          console.log({ area_id:areas[i] });
          this.diffHTMLs[areas[i]] = Diff2Html.html(Diff.createPatch(params.row.label, browser[areas[i]], _static[areas[i]], "Browser", "Static"), {
            drawFileList: false,
            matching: 'lines',
            outputFormat: 'side-by-side',
          });
        }
        this.reset_counter++;
        this.showing.id = params.row.id;
        this.showing.label = params.row.label;
        this.$swal.close();
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    async show_diff_id (){
      try{
        this.loading();
        let res = await this.axios.get("/api/sc/challenge?id="+this.target_id);
        let _static = res.data.static;
        let browser = res.data.browser;
        let areas = Object.keys(_static);
        this.diffHTMLs = {};
        for(let i=0;i<areas.length;i++){
          console.log({ area_id:areas[i] });
          this.diffHTMLs[areas[i]] = Diff2Html.html(Diff.createPatch("", browser[areas[i]], _static[areas[i]], "Browser", "Static"), {
            drawFileList: false,
            matching: 'lines',
            outputFormat: 'side-by-side',
          });
        }
        this.reset_counter++;
        this.showing.id = this.target_id;
        this.showing.label = "";
        this.$swal.close();
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    async result (applicable){
      try{
        this.loading();
        await this.axios.post("/api/sc/result", { id:this.showing.id, applicable:applicable });
        
        let idx = this.targets.findIndex(e=>e.id===this.showing.id);
        this.targets.splice(idx, 1);

        this.showing.id = 0;
        this.$swal.close();
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    rowStyleClassFn(row) {
      if (row.id==this.showing.id)
        return "showed_row";
    },
  }
}
</script>

<style lang="scss" scoped>
.summary-table {
  width: 400px;
  th,td {
    text-align: right;
  }
}

.sc-list ::v-deep {
  .feather {
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    margin-top: 1px;
  }
  thead th {
    font-size: 13px;
    vertical-align: middle;
    padding-right: .75em;
    &.filter-th {
      padding: .75em .5em .75em .5em;
    }
  }
  .showed_row {
    background-color: #cfe2ff!important;
  }
}
</style>